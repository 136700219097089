/* FurnitureCustomizer.css */
.customizer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;
    padding: 20px;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .canvas-container {
    width: 80%;
    height: 400px;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .color-picker {
    margin-bottom: 20px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
  }
  
  .color-picker label {
    margin-right: 10px;
  }
  
  input[type="color"] {
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
  }
  
  .product-info {
    width: 80%;
    text-align: left;
  }
  
  .product-info h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .product-info p {
    font-size: 1.2rem;
    margin: 5px 0;
  }
  
  @media (max-width: 768px) {
    .canvas-container {
      width: 100%;
      height: 300px;
    }
  
    .product-info {
      width: 100%;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  }
  